import React from 'react';
import { useLocation } from 'react-router-dom';

import { isAppRoute } from './utils';
import { ReactComponent as ReportSVG } from 'modules/theme/images/icons/navigation/reports.svg';
import { ReactComponent as TripSVG } from 'modules/theme/images/icons/navigation/trips.svg';
import { ROUTE } from 'modules/router/constants';
import { useConfigSelector } from 'modules/redux/states/config/hooks';
import * as Hyperlink from 'components/Hyperlink';

import styles from './styles.module.scss';

type SecondaryNavigationProps = {
  handleClose: () => void;
  id: string;
  secondaryNavRef: React.RefObject<HTMLDivElement>;
};

const SecondaryNavigation: React.FunctionComponent<
  SecondaryNavigationProps
> = ({ id, handleClose, secondaryNavRef }) => {
  const path = useLocation().pathname.split('/').slice(0, 2).join('/');
  const { eventsApplication, tripsApplication, crashApplication } =
    useConfigSelector();
  const {
    isEngagementRoute,
    isPoliciesRoute,
    isSpeedValidationRoute,
    isCrashRoute,
    title,
  } = isAppRoute(path);
  return (
    <div className={styles['secondary-nav']} ref={secondaryNavRef}>
      {title && <p>{title}</p>}
      <nav aria-label="Secondary Menu" id={id} role="navigation">
        <ul>
          {isPoliciesRoute && tripsApplication.enabled && (
            <React.Fragment>
              <li>
                <Hyperlink.NavigationLink
                  label="Policy Search"
                  onClick={handleClose}
                  route={ROUTE.POLICY_SEARCH}>
                  <TripSVG />
                </Hyperlink.NavigationLink>
              </li>
              {tripsApplication.reports.enabled && (
                <li>
                  <Hyperlink.NavigationLink
                    label="Reports"
                    onClick={handleClose}
                    route={ROUTE.REPORTS}>
                    <ReportSVG />
                  </Hyperlink.NavigationLink>
                </li>
              )}
            </React.Fragment>
          )}
          {isSpeedValidationRoute && eventsApplication.enabled && (
            <li>
              <Hyperlink.NavigationLink
                label="Events"
                onClick={handleClose}
                route={ROUTE.EVENTS}>
                <TripSVG />
              </Hyperlink.NavigationLink>
            </li>
          )}
          {isCrashRoute && crashApplication.enabled && (
            <li>
              <Hyperlink.NavigationLink
                label="Crash"
                onClick={handleClose}
                route={ROUTE.CRASHES}>
                <TripSVG />
              </Hyperlink.NavigationLink>
            </li>
          )}
          {isEngagementRoute && crashApplication.enabled && (
            <li>
              <Hyperlink.NavigationLink
                label="Policy Holders"
                onClick={handleClose}
                route={ROUTE.ENGAGEMENT_SEARCH}>
                <TripSVG />
              </Hyperlink.NavigationLink>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default React.memo(SecondaryNavigation);
