import React, { useCallback } from 'react';

import {
  ButtonColor,
  ButtonStyle,
  ButtonType,
} from 'components/Button/constants';
import { defaultValues, Model, schema } from './model';
import { QuestionPolicySearch } from 'components/Questions';
import { UsePolicySearch } from 'modules/redux/states/policy/hooks';
import Button from 'components/Button';
import Form from 'components/Form';

import styles from './styles.module.scss';

type PolicySearchFormProps = {
  handleSearch: UsePolicySearch['handleSearch'];
  isError: UsePolicySearch['isError'];
  isLoading: UsePolicySearch['isLoading'];
};

const PolicySearchForm: React.FunctionComponent<PolicySearchFormProps> = ({
  handleSearch,
  isError,
  isLoading,
}) => {
  const handleSubmit = useCallback(
    async (data: Model) => handleSearch(data.policyId),
    [handleSearch],
  );
  return (
    <Form<Model>
      className={styles['policy-number-form']}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      schema={schema}>
      {({ control, dirtyFields, isFormSubmitting }) => {
        return (
          <React.Fragment>
            <QuestionPolicySearch
              control={control}
              isError={isError}
              label="Search for policy or fleet reference"
              name="policyId"
              placeholder="e.g. 0000 0000"
              readOnly={isLoading}>
              <Button
                color={ButtonColor.BLUE}
                disabled={!dirtyFields?.policyId}
                label="Search"
                loading={isFormSubmitting || isLoading}
                style={ButtonStyle.PRIMARY}
                type={ButtonType.SUBMIT}
              />
            </QuestionPolicySearch>
          </React.Fragment>
        );
      }}
    </Form>
  );
};
export default React.memo(PolicySearchForm);
